// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDGRB0sZE2KuOmcWnFLHxN06XmeRuAuIHU",
    authDomain: "fir-demo-702a1.firebaseapp.com",
    projectId: "fir-demo-702a1",
    storageBucket: "fir-demo-702a1.appspot.com",
    messagingSenderId: "889477565714",
    appId: "1:889477565714:web:3b79dbf3f996e1647af7cb",
    measurementId: "G-ZPQQWZDL6H"
  };
// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
export default auth;















